import { Injectable } from '@angular/core';
import moment from 'moment';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { MyUtils, MyUtilsApi, Person, Router, RouterApi } from 'src/app/shared/sdk';
import { API_ROUTER_URL } from 'src/app/config';
import { TIMEZONE } from '../../trips-audit/components/trips-audit-grid/trips-audit-grid.component';
import { HttpClient } from '@angular/common/http';
import Guid from 'devextreme/core/guid';

export interface ITotals {
  totalVehicles: number;
  totalNumberOfTrips: number;
  combinedLoadedMinutes?: number;
  combinedFreeMinutes?: number;
  combinedTravelMinutes?: number;
  combinedLoadedDistance?: number;
  combinedTravelDistance?: number;
}

@Injectable()
export class RouterHelperService {
  routesMap = {};
  destinationsMap: any;
  markerBaseUrl = '/assets/images/';

  makeTripsPool(trips: any[], manifestGroups: any[], keepManifestTrips: boolean, selectedVehicleIdsSet: Set<number>) {
    let tripsPool = trips.filter(t => t.c);
    let vehicles = [];
    if (keepManifestTrips) {
      tripsPool = manifestGroups
        .filter(group => !group.vehicle || !selectedVehicleIdsSet.has(group.vehicle.id))
        .map(group => group.trips)
        .flat();
      vehicles = manifestGroups.filter(group => {
        group.preferred = selectedVehicleIdsSet.has(group.vehicle && group.vehicle.id);
        return group.preferred;
      });
    } else {
      tripsPool = tripsPool.filter(t => !t.lock);
      vehicles = JSON.parse(JSON.stringify(manifestGroups)).filter(group => {
        if (!group.vehicle) return false;
        group.trips = group.trips.filter(t => t.lock);
        group.preferred = group.trips.length > 0;
        return true;
      });
    }
    return [tripsPool, vehicles];
  }

  async proposeGroups(
    http: HttpClient,
    tripsPool: any[],
    vehiclesCount: number,
    distribute: boolean,
    withPreferred: boolean,
    withAvoid: boolean,
    useMatrix: boolean,
    vehicles: any[],
    date: string,
    dss: DataSourceService,
  ): Promise<any[]> {
    this.validateTripsPool(tripsPool);
    const body = { tripsPool, vehiclesCount, distribute, withPreferred, withAvoid, vehicles, date };
    // callId = 16 random characters
    const callId = new Guid().toString();
    const url = `${API_ROUTER_URL}/propose?callId=${callId}&useMatrix=${useMatrix}`;
    return await http.post<any>(url, body).toPromise();
    // return await dss
    //   .getApi<RouterApi>(Router)
    //   .propose({ tripsPool, vehiclesCount, distribute, withPreferred, withAvoid, vehicles, date })
    //   .toPromise();
  }

  validateTripsPool(tripsPool: any[]) {
    if (!tripsPool.every(t => t.dot && t.dst && t.dur)) throw new Error('Trips pool is invalid');
  }

  assignProposedGroups(
    dataSrc: any[],
    proposedGroups: any[],
    selectedVehicleIdsSet: Set<number>,
    selectedVehicleIds: number[],
    manifestVehiclesMap: any,
    vehiclesMap: any,
  ) {
    dataSrc = dataSrc.map(({ manifestGroup }, i) => {
      let proposedGroup = null;
      if (selectedVehicleIdsSet.has(manifestGroup.vehicle && manifestGroup.vehicle.id)) {
        let idx = proposedGroups.findIndex(
          group => group.vehicle && group.vehicle.id === manifestGroup.vehicle.id && group.preferred,
        );
        if (idx == -1) {
          const { employee, escort } = manifestGroup;
          const ids = [employee && employee.id, escort && escort.id].filter(id => id);
          idx = proposedGroups.findIndex(
            group => (!group.vehicle || !group.preferred) && !group.avoidEmployees.includes(ids),
          );
        }
        if (idx == -1) idx = proposedGroups.findIndex(group => !group.vehicle || !group.preferred);
        if (idx !== -1) {
          proposedGroup = proposedGroups.splice(idx, 1)[0];
          proposedGroup = this.prepareProposedTrips(manifestGroup, proposedGroup);
        }
      }
      return { manifestGroup, proposedGroup };
    });
    const newVehicleIds = selectedVehicleIds.filter(id => !manifestVehiclesMap[id]);
    proposedGroups.forEach((pt, i) => {
      const vehicle = vehiclesMap[newVehicleIds[i]];
      dataSrc.push({
        manifestGroup: { vehicle },
        proposedGroup: this.prepareProposedTrips({ vehicle }, pt),
      });
    });
    return dataSrc;
  }

  prepareProposedTrips(group: any, proposedGroup: any) {
    const { vehicle, employee, escort } = group;
    const v = { ...group, trips: null, ...proposedGroup, vehicle, employee, escort };
    v.load = this.calculateLoadPerGroup(v);
    return v;
  }

  makeManifestGroups(detailedTrips: any[]) {
    const [vehiclesMap] = detailedTrips.reduce(
      ([p, empl], trip) => {
        const v = trip.v || -1;
        trip.lock = false;
        if (!p[v]) {
          const employee = trip.__employee && !empl[trip.__employee.id] ? trip.__employee : null;
          if (employee) empl[employee.id] = true;
          const escort = trip.__escort && !empl[trip.__escort.id] ? trip.__escort : null;
          if (escort) empl[escort.id] = true;
          p[v] = { employee, escort, vehicle: trip.__vehicle, trips: [] };
        }
        if (trip.c) p[v].trips.push(trip);
        return [p, empl];
      },
      [{}, {}],
    );
    const groups = Object.values(vehiclesMap).map((v: any) => {
      (v.trips || []).sort(({ t: ta }: any, { t: tb }: any) => (ta < tb ? -1 : ta > tb ? 1 : 0));
      v.load = this.calculateLoadPerGroup(v);
      return v;
    });
    return groups;
  }

  calculateLoadPerGroup(v: any) {
    const trips = v.trips;
    if (!trips || !trips.length) return null;
    const changeTripTime = 30;
    const tripsCount = trips.length;
    let [loadedMinutes, loadedDistance] = trips.reduce(
      (p: number, { dur, dst }: any) => [p[0] + dur, p[1] + dst],
      [0, 0],
    );
    loadedMinutes += changeTripTime * (tripsCount - 1);
    const [travelMinutes, travelDistance] = [null, null, null, null];
    return { tripsCount, loadedMinutes, travelMinutes, travelDistance, loadedDistance };
  }

  sortDataSource(dataSource: any[], order: string, employeeWorkingTimeMap: any) {
    return dataSource.sort(({ manifestGroup: a }, { manifestGroup: b }) => {
      if (!a || !a.employee || !a.vehicle) return -1;
      if (!b || !b.employee || !b.vehicle) return 1;
      const [aTime, bTime] = [employeeWorkingTimeMap[a.employee.id], employeeWorkingTimeMap[b.employee.id]];
      let [aMin, bMin] = [
        (aTime && aTime.totalMinutesBeforeCurrent) || 0,
        (bTime && bTime.totalMinutesBeforeCurrent) || 0,
      ];
      if (order === 'least') return aMin - bMin;
      return bMin - aMin;
    });
  }

  getAddr(d: string, c: any, destinationsMap: any) {
    if (d === 'RESIDENCE') {
      const addr = c.person.contact.addresses.filter(a => a.meta.formatted)[0];
      return (addr && addr.meta.formatted) || '';
    }
    return (destinationsMap[d] && destinationsMap[d].address) || d;
  }

  getFromDoToPuAddresses(prev: any, next: any, destinationsMap: any) {
    const from = this.getAddr(prev.d, prev.__consumer, destinationsMap);
    const to = this.getAddr(next.o, next.__consumer, destinationsMap);
    return { from, to };
  }

  getFromDoToPuAddressesAndTime(date: string, prev: any, next: any, destinationsMap: any) {
    const time = moment.tz(`${date} ${next.t}`, 'YYYY-MM-DD HH:mm', TIMEZONE).utc().unix();
    return { time, ...this.getFromDoToPuAddresses(prev, next, destinationsMap) };
  }

  getRouteKey(dropOffAddress: string, pickupAddress: string, time: number) {
    return `${time}: ${dropOffAddress} -> ${pickupAddress}`;
  }

  addEmptyKeysToRoutesMap(date: string, group: any, destinationsMap) {
    if (!group || !group.trips) return;
    const trips = group.trips;
    for (let i = 0; i < trips.length - 1; i++) {
      const { from, to, time } = this.getFromDoToPuAddressesAndTime(date, trips[i], trips[i + 1], destinationsMap);
      const routeKey = this.getRouteKey(from, to, time);
      if (!this.routesMap[routeKey]) this.routesMap[routeKey] = { from, to, time };
    }
  }

  async fillRoutesMap(date: string, dataSource, destinationsMap, dss: DataSourceService) {
    for (const data of dataSource) {
      this.addEmptyKeysToRoutesMap(date, data.manifestGroup, destinationsMap);
      this.addEmptyKeysToRoutesMap(date, data.proposedGroup, destinationsMap);
    }
    const routes = Object.values(this.routesMap).filter((route: any) => !route.duration);
    if (!routes.length) return;
    const routesResp = await dss.getApi<MyUtilsApi>(MyUtils).computeRoutes(routes).toPromise();
    routes.forEach((route: any, i) => {
      const routeKey = this.getRouteKey(route.from, route.to, route.time);
      const { duration, distance } = routesResp[i] || { duration: { value: 0 }, distance: { value: 0 } };
      this.routesMap[routeKey] = { ...route, duration, distance };
    });
  }

  calculateTravelPerGroup(date: string, group: any, destinationsMap, employeeWorkingTimeMap: any) {
    if (!group || !group.trips || !group.load) return;
    const trips = group.trips;
    let travelMinutes = group.load.loadedMinutes;
    let travelDistance = group.load.loadedDistance;
    for (let i = 0; i < trips.length - 1; i++) {
      const { from, to, time } = this.getFromDoToPuAddressesAndTime(date, trips[i], trips[i + 1], destinationsMap);
      const routeKey = this.getRouteKey(from, to, time);
      if (this.routesMap[routeKey]) {
        travelMinutes += Math.floor(this.routesMap[routeKey].duration.value / 60);
        travelDistance += this.routesMap[routeKey].distance.value;
      }
    }
    const firstTrip = trips[0];
    const lastTrip = trips[trips.length - 1];
    let workingMinutes = group.workingMinutes;
    if (!workingMinutes && group.employee && employeeWorkingTimeMap[group.employee.id])
      workingMinutes = employeeWorkingTimeMap[group.employee.id].currentMinutes;
    if (!workingMinutes)
      workingMinutes = moment.duration(moment(lastTrip.dot, 'hh:mm').diff(moment(firstTrip.t, 'hh:mm'))).asMinutes();
    let percent = Math.floor((travelMinutes * 100) / workingMinutes);
    if (percent > 100) percent = 100;
    const freeMinutes = workingMinutes - travelMinutes;
    group.load = { ...group.load, percent, travelMinutes, travelDistance, freeMinutes };
  }

  async calculateTravel(
    { manifest, proposed }: { manifest: ITotals; proposed: ITotals },
    date: string,
    dataSource,
    destinationsMap,
    dss: DataSourceService,
    employeeWorkingTimeMap,
  ) {
    await this.fillRoutesMap(date, dataSource, destinationsMap, dss);
    for (const data of dataSource) {
      this.calculateTravelPerGroup(date, data.manifestGroup, destinationsMap, employeeWorkingTimeMap);
      this.calculateTravelPerGroup(date, data.proposedGroup, destinationsMap, employeeWorkingTimeMap);
    }

    this.calculateCombinedTravel(
      manifest,
      dataSource.map(d => d.manifestGroup),
    );
    this.calculateCombinedTravel(
      proposed,
      dataSource.map(d => d.proposedGroup),
    );
  }

  calculateCombinedTravel(totals: ITotals, groups: any[]) {
    const groupsWithVehicle = groups.filter(v => v && v.vehicle && v.load);
    const [totalMinutes, totalDistance] = groupsWithVehicle.reduce(
      (p: number, { load: { travelMinutes, travelDistance } }: any) => [p[0] + travelMinutes, p[1] + travelDistance],
      [0, 0],
    );
    totals.combinedTravelMinutes = totalMinutes;
    totals.combinedTravelDistance = totalDistance;
    totals.combinedFreeMinutes =
      totalMinutes > totals.combinedLoadedMinutes ? totalMinutes - totals.combinedLoadedMinutes : 0;
  }

  calculateTotals(groups: any[]): ITotals {
    const groupsWithVehicle: any[] = groups.filter(v => v.vehicle);
    const totalVehicles = groupsWithVehicle.length;
    const [totalLoadedMin, totalFreeMin, totalDistance] = groups
      .filter(g => g.load)
      .reduce(
        (p: number, { load: { loadedMinutes, freeMinutes, loadedDistance } }: any) => [
          p[0] + loadedMinutes,
          p[1] + freeMinutes,
          p[2] + loadedDistance,
        ],
        [0, 0, 0],
      );

    const totalNumberOfTrips = groups
      .filter(g => g.load)
      .reduce((p: number, { load: { tripsCount } }: any) => p + tripsCount, 0);
    return {
      totalVehicles,
      combinedLoadedMinutes: totalLoadedMin,
      totalNumberOfTrips,
      combinedLoadedDistance: totalDistance,
      combinedTravelMinutes: null,
      combinedTravelDistance: null,
    };
  }

  getRoutesAndMarkers(trips: any, destinationsMap) {
    const routes = [];
    const markers = [];
    for (let i = 0; i < trips.length; i++) {
      const trip = trips[i];
      const origing = this.getAddr(trip.o, trip.__consumer, destinationsMap);
      const destination = this.getAddr(trip.d, trip.__consumer, destinationsMap);
      routes.push(this.getRoute([origing, destination], false, i));
      const nextTrip = trips[i + 1];
      if (nextTrip) {
        const nextOriging = this.getAddr(nextTrip.o, nextTrip.__consumer, destinationsMap);
        routes.push(this.getRoute([destination, nextOriging], true));
      }
      markers.push(
        ...this.getPuDoMarkers(
          [
            { location: origing, dateTime: trip.t },
            { location: destination, dateTime: trip.dot },
          ],
          trip.__consumer.person,
        ),
      );
    }
    return { routes, markers };
  }

  private getPuDoMarkers(positions, ePerson: Person): any[] {
    return [
      { ...positions[0], iconSrc: this.markerBaseUrl + 'marker-pickup.png', isPU: true },
      { ...positions[1], iconSrc: this.markerBaseUrl + `marker-dropoff.png` },
    ].map(({ dateTime, location, iconSrc, isPU }) => ({
      iconSrc,
      location: `${location}`,
      tooltip: {
        text:
          `${ePerson.lastname}, ${ePerson.firstname}` +
          `<br/><em>${isPU ? 'Pick Up' : 'Drop Off'} Time:</em> ${moment(dateTime, 'HH:mm:ss').format('hh:mm A')}`,
        isShown: false,
      },
    }));
  }

  getTripAddresses(trip: any, destinationsMap) {
    const from = this.getAddr(trip.o, trip.__consumer, destinationsMap);
    const to = this.getAddr(trip.d, trip.__consumer, destinationsMap);
    return { from, to };
  }

  private getRoute(locations, isBetween, trupNumber = 0) {
    const colors = ['red', 'green', 'blue', 'brown', '#0083ff', '#3cbc4d', '#a02370', '#7f7213', '#12677c'];
    return {
      weight: 4,
      color: isBetween ? 'red' : colors[trupNumber % colors.length],
      opacity: isBetween ? 0.3 : 0.8,
      mode: '',
      locations: [...locations],
      tooltip: {
        text: 'asdf',
        isShown: true,
      },
    };
  }
}
